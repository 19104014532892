export const myFull = (fn) => {
    return {
        show: true,
        title: "全屏查看",
        icon: "M475.3408 850.5344h-266.24a37.2736 37.2736 0 0 1-37.2736-37.2736v-266.24a37.2736 37.2736 0 1 1 74.5472 0v228.9664h228.9664a37.2736 37.2736 0 0 1 0 74.5472zM830.3104 495.5648a37.2736 37.2736 0 0 1-37.2736-37.2736V229.3248h-228.9664a37.2736 37.2736 0 1 1 0-74.5472h266.24a37.2736 37.2736 0 0 1 37.2736 37.2736v266.24a37.2736 37.2736 0 0 1-37.2736 37.2736z",
        onclick: (e) => {
            const element = fn();
            //   console.log(this.$refs.chart.$el, "click");
            //   // 注：yourEchartsId: 你的图表id
            //   const element = this.$refs.chart.$el;
            if (element.requestFullScreen) {
                // HTML W3C 提议
                element.requestFullScreen();
            } else if (element.msRequestFullscreen) {
                // IE11
                element.msRequestFullScreen();
            } else if (element.webkitRequestFullScreen) {
                // Webkit (works in Safari5.1 and Chrome 15)
                element.webkitRequestFullScreen();
            } else if (element.mozRequestFullScreen) {
                // Firefox (works in nightly)
                element.mozRequestFullScreen();
            }
            // 退出全屏
            if (element.requestFullScreen) {
                document.exitFullscreen();
            } else if (element.msRequestFullScreen) {
                document.msExitFullscreen();
            } else if (element.webkitRequestFullScreen) {
                document.webkitCancelFullScreen();
            } else if (element.mozRequestFullScreen) {
                document.mozCancelFullScreen();
            }
        },
    }
}

export const downloadCSV = (fn, flag = true) => {
    return {
        show: true,
        title: "Download CSV",
        icon: "path://M546.304 580.224l57.6-75.008c12.8-16.768 32.32-19.904 49.088-7.04 16.704 12.8 17.28 32 4.48 48.768L546.304 690.56c-15.36 19.968-49.408 19.968-64.64 0L370.368 546.944c-12.8-16.704-11.072-35.968 5.696-48.768 16.768-12.864 35.072-9.728 47.872 7.04l57.6 75.008V165.376c0-21.12 11.264-38.208 32.32-38.208 21.12 0 32.384 17.152 32.384 38.208v414.848z m288.448-70.08c0-21.12 10.176-38.208 31.296-38.208 21.12 0 31.232 12.992 31.232 34.048v275.2c0 59.072-38.4 111.168-97.472 111.168H225.344c-59.136 0-95.744-47.936-95.744-107.008v-275.2c0-21.12 10.496-38.208 31.616-38.208 21.12 0 31.552 17.152 31.552 38.208v275.2c0 16.896 15.68 47.296 32.576 47.296h574.464c16.896 0 34.944-30.4 34.944-47.36V510.208z",
        onclick: (e) => {
            const tableRows = fn();
            // console.log(tableRows, "click");
            //console.log(tableRows, "click");
            //   const temp1 = this.$refs.chart;
            //   const tableRows = [
            //     temp1.option.xAxis.data,
            //     ...temp1.option.series.map((v) => v.data),
            //   ];
            // 构造数据字符，换行需要用\r\n
            let allData;
            if (flag) {
                if (tableRows[0].data) {
                    allData = [tableRows[0].data, ...tableRows.slice(1)]
                } else {
                    allData = [...tableRows[0].map(v => v.data), ...tableRows.slice(1)]
                }

            } else {
                allData = tableRows;
            }
            // console.log(tableRows, allData, "click");
            let CsvString = allData
                .map((data) => data.join(","))
                .join("\r\n");
            // 加上 CSV 文件头标识
            CsvString =
                "data:text/csv;charset=utf-8,\uFEFF" +
                encodeURIComponent(CsvString);
            // 通过创建a标签下载
            const link = document.createElement("a");
            link.href = CsvString;
            // 对下载的文件命名
            link.download = `Download CSV`;
            // 模拟点击下载
            link.click();
            // 移除a标签
            link.remove();
        },
    }
}