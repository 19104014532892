import { formatNum } from './utils'

export const config = {
  BG: "#33353E",
  DEFAULT_COLORS: [
    "rgba(59, 162, 114, 1)",
    "rgba(50, 159, 212, 1)",
    "rgba(145, 204, 118, 1)",
    "rgba(85, 112, 198, 1)",
    "rgba(0, 188, 212, 1)",
    "rgba(252, 132, 82, 1)",
  ],
  SPLIT_LINE: {
    show: true,
    lineStyle: {
      type: "dashed",
      color: "rgba(255, 255, 255, 0.1)",
    },
  },
  PIE_RICH: {
    a: {
      color: "#D1D4DC",
      fontSize: 12, // 根据需要调整字体大小
      lineHeight: 12, // 根据需要调整行高
      align: "center",
    },
    b: {
      color: "#D1D4DC",
      fontSize: 12, // 根据需要调整字体大小
      lineHeight: 12, // 根据需要调整行高
      align: "center",
    },
  },
  ANIMATION: {
    animationType: "scale",
    animationEasing: "elasticOut",
    animationDelay: function (idx) {
      return Math.random() * 200;
    }
  },
  PIE_LABEL_LINE: {
    show: true,
    length: 50,
    // length2: 20, // 设置引导线长度
    //   smooth: 0.5, // 可选，使引导线平滑显示
  },
  PIE_LEGEND: {
    top: 20,
    textStyle: {
      fontSize: 12,
      color: "#D1D4DC",
    },
    padding: [0, 32, 0, 0],
    itemHeight: 4,
    itemWidth: 12,
    itemGap: 40,
    data: formatterLegendData,
  },
  PIE_TOOLTIP: {
    borderWidth: "0", //边框宽度设置1
    borderColor: "none", //设置边框颜色
    textStyle: {
      fontSize: 12,
      color: "rgba(209, 212, 220, 1)", //设置文字颜色
    },

    tooltip: {
      show: true,
    },
    extraCssText: "background:rgba(61, 63, 71, 1);",
    trigger: "item",
    formatter: formatterPieTootip,
  },
  LINE_TOOLTIP: {
    trigger: "axis",
    borderWidth: "0", //边框宽度设置1
    borderColor: "none", //设置边框颜色
    padding: 16,
    textStyle: {
      fontSize: 12,
      color: "rgba(209, 212, 220, 1)", //设置文字颜色
    },
    tooltip: {
      show: true,
    },
    extraCssText: "background:rgba(61, 63, 71, 1);",
    formatter: (params) => {
      let weekDays = this.weekDay(params[0].name);
      let result = `<div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
           ${params[0].name}
          </div>`;
      params.reverse();
      params.forEach((item) => {
        var re = item.seriesName;
        var colors = "";
        switch (re) {
          case "总资产规模（$  Mlns)":
            colors = "rgba(255, 134, 0, 1)";
            break;
          case "每日资产变化（$  Mlns)":
            colors = "rgba(74, 181, 232, 1)";
            break;
          case "每日BTC持有量变化(万枚）":
            colors = "rgba(234, 204, 174, 1)";
            break;
        }
        var aaa = this.formatNum(item.data);
        result += `<span style="color:${colors}">${item.seriesName} : ${aaa}</span><br/>  `;
      });
      return result;
    },
  },
  NOT_DATA_TITLE: {
    text: "暂无数据",
    left: "center",
    top: "center",
    textStyle: {
      color: "#D1D4DC",
      fontSize: 14,
      fontWeight: "normal",
    },
  },
  NOT_DATA_OPTION: {
    title: {
      text: "暂无数据",
      left: "center",
      top: "center",
      textStyle: {
        color: "#D1D4DC",
        fontSize: 14,
        fontWeight: "normal",
      },
    },
    xAxis: {
      data: [],
    },
    series: [
      {
        data: [],
      },
    ],
  }
}

export const number2String = (params, total) => {
  let v = params.value;
  let percent = ((v / total) * 100).toFixed(2);

  if (v > 1000 && v <= 1000000) {
    return `{a|${params.name}}\n{b|${(v / 1000).toFixed(2) + "K" + '(' + percent + "%" + ')'
      }}`;
  } else if (v > 1000000 && v <= 1000000000) {
    return `{a|${params.name}}\n{b|${(v / 1000000).toFixed(2) + "M" + '(' + percent + "%" + ')'
      }}`;
  } else if (v > 1000000000) {
    return `{a|${params.name}}\n{b|${(v / 1000000000).toFixed(2) + "B" + '(' + percent + "%" + ')'
      }}`;
  } else {
    return `{a|${params.name}}\n{b|${v.toFixed(2) + '(' + percent + "%" + ')'
      }}`;
  }
}


export const formatterPieTootip = (params) => {
  return `${params.data.name} : ${formatNum(params.data.value)}`;
}

export const formatterLegendData = data => {
  if (!data) return []

  return data.map((item) => ({
    name: item.name,
    icon: "rect",
  }))
};

export const graphic = (image) => [
  {
    type: "image",
    id: "logo",
    right: "center",
    bottom: "5%",
    z: 0,
    bounding: "all",
    style: {
      image, // 必须是https开头的图片路径地址
      width: 180,
      height: 31,
    },
  },
]
export const graphicBig = (image) => [
  {
    type: "image",
    id: "logo",
    right: "center",
    bottom: "center",
    z: 0,
    bounding: "all",
    style: {
      image, // 必须是https开头的图片路径地址
      width: 400,
      height: 68,
    },
  },
]

export const formatterPieData = (data) => {
  if (!data) return []

  return data.map((item, index) => {
    return {
      value: item.value,
      name: item.name,
      itemStyle: { color: config.DEFAULT_COLORS[index] },
    };
  })
}

export const fromatterPieOptions = (data, name, radius, center) => {

  if (!data || !data.length) return {
    tooltip: { show: false },
    series: [],
    title: config.NOT_DATA_TITLE,
  }
  let total = data.reduce((sum, item) => sum + item.value, 0);

  return {
    backgroundColor: config.BG,
    tooltip: config.PIE_TOOLTIP,
    legend: {
      ...config.PIE_LEGEND,
      data: formatterLegendData(data)
    },
    series: [
      {
        // name: name || "Block Trade Breakdown",
        type: "pie",
        radius: radius || "55%",
        center: center || ["50%", "55%"],
        data: formatterPieData(data),
        avoidLabelOverlap: true,
        // minAngle: 30, //最小角度
        startAngle: 0,
        label: {
          color: "#D1D4DC",
          show: true,
          position: "outer",
          distance: 10,
          formatter: (params) => number2String(params, total),
          rich: config.PIE_RICH,
          labelLine: config.PIE_LABEL_LINE,
          ...config.ANIMATION,
        },
      }
    ],
    graphic: graphic('echarts-bg_yYgTFo1.png'),
  }
}




export default {
  tooltip: {
    trigger: "axis",
    borderWidth: "0", //边框宽度设置1
    borderColor: "none", //设置边框颜色
    padding: 16,
    textStyle: {
      fontSize: 12,
      color: "rgba(209, 212, 220, 1)", //设置文字颜色
    },
    tooltip: {
      show: true,
    },
    extraCssText: "background:rgba(61, 63, 71, 1);",
    formatter: (params) => {
      let aaa = this.kmb(params[0].data);
      let bbb = this.kmb(params[1].data);
      let ccc = this.kmb(params[2].data);
      return `
            <div>
              <div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                ${params[0].name}
              </div>
              <div><span style="color:#4AB5E8">${params[0].seriesName}: ${aaa}</span><br/>
              <span style="color:#EACCAE">${params[1].seriesName}: ${bbb}</span><br/>
             <span style="color:#B7EAE2"> ${params[2].seriesName}: ${ccc}</span><br/></div>
              
            </div>
          `;
    },
  },
  toolbox: {
    right: "2%",
    iconStyle: {
      borderColor: "#b4b7bd",
    },
    feature: {
      dataZoom: {
        // title: {
        //   zoom: "aaa",
        // },
        // icon: {
        //   zoom: 'image://http://example.website/a/b.png',
        // },
        yAxisIndex: "none",
      },
      //  dataView: { show: true, readOnly: false },
      // magicType: { show: true, type: ["line", "bar"] },
      // restore: { show: true },
      saveAsImage: { show: true },
    },
  },
  grid: {
    left: "2%",
    right: "4%",
    bottom: "16%",
    containLabel: true,
  },
  legend: {
    textStyle: {
      fontSize: 12,
      color: "#D1D4DC",
    },
    padding: [0, 32, 0, 0],
    itemHeight: 12,
    itemWidth: 12,
    itemGap: 40,
    data: [
      {
        name: "Monthly Options",
        icon: "roundRect",
      },
      {
        name: "Weekly Options",
        icon: "roundRect",
      },
      {
        name: "Daily Options",
        icon: "roundRect",
      },
    ],
  },
  dataZoom: [
    {
      type: "inside",
      start: 0,
      end: 100,
    },
    {
      start: 0,
      end: 100,
    },
  ],
  xAxis: [
    {
      type: "category",
      axisPointer: {
        type: "shadow",
      },
      axisLabel: {
        showMaxLabel: true,
        // rotate: 60,
        textStyle: {
          color: "rgba(209, 212, 220, 1)",
        },
      },
    },
  ],
  yAxis: [
    {
      type: "log",
      axisLabel: {
        formatter: function (v) {
          if (v > 1000 && v <= 1000000) {
            return v / 1000 + "K";
          } else if (v > 1000000 && v <= 1000000000) {
            return v / 1000000 + "M";
          } else if (v > 1000000000) {
            return v / 1000000000 + "B";
          } else {
            return v;
          }
          // return v + 'K';
        },
        textStyle: {
          color: "rgba(209, 212, 220, 1)",
        },
        margin: 20,
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: "dashed",
          color: "rgba(255, 255, 255, 0.1)",
        },
      },
    },
  ],
  graphic: [
    {
      type: "image",
      id: "logo",
      right: "center",
      bottom: "center",
      z: 0,
      bounding: "all",
      style: {
        image:
          "https://apilib.rta.academy/media/2023/07/26/echarts-bg_yYgTFo1.png", // 必须是https开头的图片路径地址
        width: 400,
        height: 68,
      },
    },
  ],
};